import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import T from "../Helpers/Translations";
import * as actions from "../Store/Actions/type";
import * as userActionCreater from "../Store/Actions/UserActions";
import PackageService from "../Services/PackageService";
import alertify from "alertifyjs";
import APIModel from "../Models/APIModel";
import axios from "axios";
import Script from "react-load-script";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      packages: [],
      language: "",
      testimonials: [],
      monthly: "",
      yearly: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      message: "",
      host: APIModel.HOST,
      scriptLoaded: "",
      scriptError: "",
      currencySymbol: "$",
      urllan: "",
    };

    this.getTestimonials = this.getTestimonials.bind(this);
    this.getPackages = this.getPackages.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.getpackageprice = this.getpackageprice.bind(this);
    this.currencySymbol = this.currencySymbol.bind(this);
  }
  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  currencySymbol(e) {
    if (e == "English") {
      $(".yearly-image").attr("dir", "ltr");
      $(".monthly-image").attr("dir", "ltr");
      this.setState({ urllan: "" });
      $("html").attr("lang", "En");
      $("#create-He").css("display", "none");
      $("#create-Ar").css("display", "none");
      $("#create-En").css("display", "block");

      $("#pr-He").css("display", "none");
      $("#pr-Ar").css("display", "none");
      $("#pr-En").css("display", "block");

      $("#comp-He").css("display", "none");
      $("#comp-Ar").css("display", "none");
      $("#comp-En").css("display", "block");

      $("#share-He").css("display", "none");
      $("#share-Ar").css("display", "none");
      $("#share-En").css("display", "block");

      $(".yearly-imageAb")
        .addClass("yearly-image")
        .removeClass("yearly-imageAb")
        .removeClass("yearly-imageHb");
      $(".monthly-imageAb")
        .addClass("monthly-image")
        .removeClass("monthly-imageAb")
        .removeClass("monthly-imageHb");

      $(".yearly-imageHb")
        .addClass("yearly-image")
        .removeClass("yearly-imageAb")
        .removeClass("yearly-imageHb");

      $(".monthly-imageHb")
        .addClass("monthly-image")
        .removeClass("monthly-imageAb")
        .removeClass("monthly-imageHb");

      return "$";
    }
    if (e == "Hebrew") {
      $(".yearly-image").attr("dir", "ltr");
      $(".monthly-image").attr("dir", "ltr");
      this.setState({ urllan: "Hb" });
      $("html").attr("lang", "He");
      $("#create-He").css("display", "block");
      $("#create-Ar").css("display", "none");
      $("#create-En").css("display", "none");

      $("#pr-He").css("display", "block");
      $("#pr-Ar").css("display", "none");
      $("#pr-En").css("display", "none");

      $("#comp-He").css("display", "block");
      $("#comp-Ar").css("display", "none");
      $("#comp-En").css("display", "none");

      $("#share-He").css("display", "block");
      $("#share-Ar").css("display", "none");
      $("#share-En").css("display", "none");

      $(".yearly-image")
        .addClass("yearly-imageHb")
        .removeClass("yearly-image")
        .removeClass("yearly-imageAb");
      $(".monthly-image")
        .addClass("monthly-imageHb")
        .removeClass("monthly-image")
        .removeClass("yearly-imageAb");

      $(".yearly-imageAb")
        .addClass("yearly-imageHb")
        .removeClass("yearly-image")
        .removeClass("yearly-imageAb");
      $(".monthly-imageAb")
        .addClass("monthly-imageHb")
        .removeClass("monthly-image")
        .removeClass("yearly-imageAb");

      return 'ש"ח';
    }
    if (e == "Arabic") {
      $(".yearly-image").attr("dir", "ltr");
      $(".monthly-image").attr("dir", "ltr");
      this.setState({ urllan: "Ab" });
      $("html").attr("lang", "Ar");
      $("#create-He").css("display", "none");
      $("#create-Ar").css("display", "block");
      $("#create-En").css("display", "none");

      $("#pr-He").css("display", "none");
      $("#pr-Ar").css("display", "block");
      $("#pr-En").css("display", "none");

      $("#comp-He").css("display", "none");
      $("#comp-Ar").css("display", "block");
      $("#comp-En").css("display", "none");

      $(".yearly-image")
        .addClass("yearly-imageAb")
        .removeClass("yearly-image")
        .removeClass("yearly-imageHb");
      $(".monthly-image")
        .addClass("monthly-imageAb")
        .removeClass("monthly-image")
        .removeClass("yearly-imageHb");
      $(".yearly-imageHb")
        .addClass("yearly-imageAb")
        .removeClass("yearly-imageHb")
        .removeClass("yearly-imageHb");
      $(".monthly-imageHb")
        .addClass("monthly-imageAb")
        .removeClass("monthly-imageHb")
        .removeClass("yearly-imageHb");

      $("#share-He").css("display", "none");
      $("#share-Ar").css("display", "block");
      $("#share-En").css("display", "none");
      return "$";
    }
  }

  getPackages = () => {
    let e = this.state.language;
    PackageService.get(e)
      .then((response) => {
        if (response.data.data.length == 0) {
          let z = this.currencySymbol(e);
          this.setState({ monthly: 0 });
          this.setState({ yearly: 0 });
          this.setState({ currencySymbol: z });
        } else {
          let z = this.currencySymbol(e);
          this.setState({ monthly: response.data.data[1].price });
          this.setState({ yearly: response.data.data[0].price });
          this.setState({ currencySymbol: z });
        }
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  getpackageprice = (e) => {
    if (e === "Monthly") {
      let p = this.state.monthly;
      return p;
    }

    if (e === "Yearly") {
      let p = this.state.yearly;
      return p;
    }
  };

  getTestimonials = () => {
    let { getTestimonials, errorHandler } = this.props;
    getTestimonials()
      .then((res) => {
        this.setState({
          testimonials: res.data.data,
        });
        //   console.log(this.state.testimonials);
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  componentDidMount() {
    alertify.set("notifier", "position", "top-right");
    this.getTestimonials();
    // this.HebrewLanguage();
    var z = localStorage.getItem("lang");
    this.getPackages(z);
    $("#create-He").css("display", "none");
    $("#create-Ar").css("display", "none");

    $("#pr-Ar").css("display", "none");
    $("#pr-He").css("display", "none");

    $("#comp-Ar").css("display", "none");
    $("#comp-He").css("display", "none");

    $("#share-Ar").css("display", "none");
    $("#share-He").css("display", "none");
  }

  componentWillMount() {
    var z = localStorage.getItem("lang");
    if (z != null) {
      if (z == "English") {
        this.setState({ language: "English" });
        $("html").attr("dir", "ltr");
      }

      if (z == "Arabic") {
        this.setState({ language: "Arabic" });
        $("html").attr("dir", "rtl");
      }

      if (z == "Hebrew") {
        this.setState({ language: "Hebrew" });
        $("html").attr("dir", "rtl");
      }
    } else {
      localStorage.setItem("lang", "English");
      this.setState({ language: "English" });
      $("html").attr("dir", "ltr");
    }
    var p = localStorage.getItem("lang");
    this.getTestimonials();

    if (
      this.props.user !== null &&
      this.props.user !== "null" &&
      this.props.user !== undefined
    ) {
      this.setState({
        user: this.props.user,
      });
      let lang = this.props.user.language;
      if (lang === "HE" || lang === "AR") {
        $("html").attr("dir", "rtl");
        $("html").attr("lang", lang);
        this.render();
      } else {
        $("html").attr("dir", "ltr");
        $("html").attr("lang", lang);
        this.render();
      }
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validatenumber(num) {
    const re = /^[0-9]+$/;
    return re.test(num);
  }
  sendMessage = () => {
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];
    if (this.state) {
      if (this.state.first_name === "" || this.state.first_name === null) {
        alertify.error(t.contactformfname[lang]);
        return false;
      }

      if (this.state.last_name === "" || this.state.last_name === null) {
        alertify.error(t.contactformlname[lang]);
        return false;
      }

      if (this.state.message === "" || this.state.message === null) {
        alertify.error(t.contactformmessage[lang]);
        return false;
      }

      if (this.state.phone_number === "" || this.state.phone_number === null) {
        alertify.error(t.contactformnumber[lang]);
        return false;
      }

      if (this.validateEmail(this.state.email)) {
      } else {
        alertify.error(t.contactformemailvalid[lang]);
        return false;
      }

      if (this.validatenumber(this.state.phone_number)) {
      } else {
        alertify.error(t.contactformnumberv[lang]);
        return false;
      }

      let params = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        from_number: this.state.phone_number,
        from_email: this.state.email,
        from_message: this.state.message,
        language: lang,
      };

      return axios
        .post(this.state.host + "admin/message/feedback", params, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          alertify.success(response.data.message);
          this.setState({
            first_name: "",
            last_name: "",
            phone_number: "",
            email: "",
            message: "",
          });
        })
        .catch(this.globalErrorHandler)
        .finally();
    }
  };

  EnglishLanguage = () => {
    localStorage.setItem("lang", "English");
    this.setState({ language: "English" }, () => {
      $("html").attr("dir", "ltr");
      this.getPackages("English");
    });
  };
  HebrewLanguage = () => {
    localStorage.setItem("lang", "Hebrew");
    this.setState({ language: "Hebrew" }, () => {
      $("html").attr("dir", "rtl");
      this.getPackages("Hebrew");
    });
  };
  ArabicLanguage = () => {
    localStorage.setItem("lang", "Arabic");
    this.setState({ language: "Arabic" }, () => {
      $("html").attr("dir", "rtl");
      this.getPackages("Arabic");
    });
  };

  renderTestimonials() {
    return this.state.testimonials.map((product) => {
      return (
        <div className="review-single">
          <img
            className="img-circle"
            src={product.avatar ? product.avatar : "assets/images/ava.png"}
            alt="Client Testimonoal"
          />
          <div className="review-text wow fadeInUp" data-wow-delay="0.2s">
            <p>{product.content}</p>
            <h3>- {product.name}</h3>
            <h3>{product.position}</h3>
          </div>
        </div>
      );
    });
  }

  render() {
    let { testimonials } = this.state;
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];
    return (
      <div className="wrapper">
        <Script
          url="/assets/js/sliderfix.js"
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <div className="container">
          <nav className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="navbar-header page-scroll">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand page-scroll" href="#main">
                  <img
                    src="assets/images/easy-card.png"
                    width="105"
                    height="35"
                    alt="iLand"
                  />
                </a>
              </div>
              <div
                className="collapse navbar-collapse navbar-right"
                id="bs-example-navbar-collapse-1"
              >
                {lang == "EN" ? (
                  <ul className="nav navbar-nav">
                    <li>
                      <a className="page-scroll" href="#main">
                        {t.Home[lang]}
                      </a>
                    </li>
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        href="#"
                      >
                        {t.ourproduct[lang]}
                        <span className="caret"></span>
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a
                            className="dropdown-item page-scroll"
                            href="#features"
                          >
                            {t.FeaturesOverviews[lang]}
                          </a>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a
                            className="dropdown-item page-scroll"
                            href="#price"
                          >
                            {t.Price[lang]}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a className="page-scroll" href="#how-it-works">
                        {t.Howitworks[lang]}
                      </a>
                    </li>
                    <li>
                      <a className="page-scroll" href="#img-slider-background">
                        {t.Examples[lang]}
                      </a>
                    </li>
                    <li>
                      <a className="page-scroll" href="#reviews">
                        {t.Reviews[lang]}
                      </a>
                    </li>
                    <li>
                      <a className="page-scroll" href="#contact-us">
                        {t.ContactUs[lang]}
                      </a>
                    </li>
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        href="#"
                      >
                        <img id="globe-img" src="assets/images/world2.png" />
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.EnglishLanguage}
                          >
                            English
                          </button>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.HebrewLanguage}
                          >
                            עברית
                          </button>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.ArabicLanguage}
                          >
                            العربية
                          </button>
                        </li>
                      </ul>
                    </li>
                    {this.state.user !== "" &&
                    this.state.user !== undefined &&
                    this.state.user.length === 0 ? (
                      <li>
                        <a
                          className="page-scroll"
                          href="https://portal.easy-card.co/profile"
                        >
                          Profile
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a
                          className="page-scroll"
                          href={`https://portal.easy-card.co/#/login/${lang}`}
                        >
                          {t.Signin[lang]}
                        </a>
                      </li>
                    )}
                  </ul>
                ) : (
                  ""
                )}
                {lang == "HE" ? (
                  <ul className="nav navbar-nav">
                    <li>
                      <a className="page-scroll" href="#contact-us">
                        {t.ContactUs[lang]}
                      </a>
                    </li>

                    <li>
                      <a className="page-scroll" href="#reviews">
                        {t.Reviews[lang]}
                      </a>
                    </li>

                    <li>
                      <a className="page-scroll" href="#img-slider-background">
                        {t.Examples[lang]}
                      </a>
                    </li>

                    <li>
                      <a className="page-scroll" href="#how-it-works">
                        {t.Howitworks[lang]}
                      </a>
                    </li>

                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        href="#"
                      >
                        {t.ourproduct[lang]}
                        <span className="caret"></span>
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a
                            className="dropdown-item page-scroll"
                            href="#features"
                          >
                            {t.FeaturesOverviews[lang]}
                          </a>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a
                            className="dropdown-item page-scroll"
                            href="#price"
                          >
                            {t.Price[lang]}
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a className="page-scroll" href="#main">
                        {t.Home[lang]}
                      </a>
                    </li>

                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        href="#"
                      >
                        <img id="globe-img" src="assets/images/world2.png" />
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.EnglishLanguage}
                          >
                            English
                          </button>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.HebrewLanguage}
                          >
                            עברית
                          </button>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.ArabicLanguage}
                          >
                            العربية
                          </button>
                        </li>
                      </ul>
                    </li>
                    {this.state.user !== "" &&
                    this.state.user !== undefined &&
                    this.state.user.length === 0 ? (
                      <li>
                        <a
                          className="page-scroll"
                          href="https://portal.easy-card.co/profile"
                        >
                          Profile
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a
                          className="page-scroll"
                          href={`https://portal.easy-card.co/#/login/${lang}`}
                        >
                          {t.Signin[lang]}
                        </a>
                      </li>
                    )}
                  </ul>
                ) : (
                  ""
                )}
                {lang == "AR" ? (
                  <ul className="nav navbar-nav">
                    <li>
                      <a className="page-scroll" href="#contact-us">
                        {t.ContactUs[lang]}
                      </a>
                    </li>

                    <li>
                      <a className="page-scroll" href="#reviews">
                        {t.Reviews[lang]}
                      </a>
                    </li>

                    <li>
                      <a className="page-scroll" href="#img-slider-background">
                        {t.Examples[lang]}
                      </a>
                    </li>

                    <li>
                      <a className="page-scroll" href="#how-it-works">
                        {t.Howitworks[lang]}
                      </a>
                    </li>

                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        href="#"
                      >
                        {t.ourproduct[lang]}
                        <span className="caret"></span>
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a
                            className="dropdown-item page-scroll"
                            href="#features"
                          >
                            {t.FeaturesOverviews[lang]}
                          </a>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a
                            className="dropdown-item page-scroll"
                            href="#price"
                          >
                            {t.Price[lang]}
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a className="page-scroll" href="#main">
                        {t.Home[lang]}
                      </a>
                    </li>

                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        href="#"
                      >
                        <img id="globe-img" src="assets/images/world2.png" />
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.EnglishLanguage}
                          >
                            English
                          </button>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.HebrewLanguage}
                          >
                            עברית
                          </button>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <button
                            className="dropdown-item changeLang"
                            type="button"
                            onClick={this.ArabicLanguage}
                          >
                            العربية
                          </button>
                        </li>
                      </ul>
                    </li>
                    {this.state.user !== "" &&
                    this.state.user !== undefined &&
                    this.state.user.length === 0 ? (
                      <li>
                        <a
                          className="page-scroll"
                          href="https://portal.easy-card.co/profile"
                        >
                          Profile
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a
                          className="page-scroll"
                          href={`https://portal.easy-card.co/#/login/${lang}`}
                        >
                          {t.Signin[lang]}
                        </a>
                      </li>
                    )}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </nav>
        </div>
        <div className="main form" id="main">
          <div className="hero-section">
            <div className="container nopadding">
              <div className="col-md-5">
                <img
                  className="img-responsive wow fadeInUp her-section-img"
                  data-wow-delay="0.1s"
                  src="assets/images/pic1.png"
                  alt="Easy Card"
                />
              </div>
              <div className="col-md-7">
                <div className="hero-content">
                  <h1 className="wow fadeInUp" data-wow-delay="0.1s">
                    {t.main[lang]}
                  </h1>
                  <p className="wow fadeInUp" data-wow-delay="0.2s">
                    {" "}
                    <i className="fas fa-check"></i> &nbsp;
                    {t.main1[lang]}
                    <br /> <i className="fas fa-check"></i> &nbsp;{" "}
                    {t.main2[lang]}
                    <br /> <i className="fas fa-check"></i> &nbsp;{" "}
                    {t.main3[lang]}
                    <br /> <i className="fas fa-check"></i> &nbsp;{" "}
                    {t.main4[lang]}
                  </p>
                  <div className="sub-form wow fadeInUp" data-wow-delay="0.3s">
                    <a
                      href={`https://portal.easy-card.co/#/register/${lang}`}
                      className="btn btn-success btn1"
                    >
                      {t.mainbtn[lang]}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="app-features text-center" id="features">
            <div className="container">
              <div id="container-inner-div">
                <h1 className="wow fadeInDown" data-wow-delay="0.1s">
                  {t.FeaturesOverviews[lang]}
                </h1>
              </div>
              <div className="col-md-4 col-sm-12 features-left">
                <div
                  className="col-md-12 col-sm-6 wow fadeInDown"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    {" "}
                    <i className="ion-ios-analytics-outline"></i>
                  </div>
                  <div className="feature-single">
                    <h1>
                      {t.CREATEYOUROWNCUSTOMIZEDCARD[lang]}
                      <br></br>
                      {t.CREATEYOUROWNCUSTOMIZEDCARD1[lang]}
                    </h1>
                    <hr id="line" />
                  </div>
                </div>
                <div
                  className="col-md-12 col-sm-6 wow fadeInDown"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    {" "}
                    <i className="ion-ios-copy-outline"></i>
                  </div>
                  <div className="feature-single">
                    <h1>
                      {t.CONTACTDETAILSANDEASYCONNECTION[lang]}
                      <br></br>
                      {t.CONTACTDETAILSANDEASYCONNECTION1[lang]}
                    </h1>
                    <hr id="line" />
                  </div>
                </div>
                <div
                  className="col-md-12 col-sm-12 wow fadeInDown buiness-information"
                  data-wow-delay="0.4s"
                >
                  <div className="icon">
                    {" "}
                    <i className="ion-ios-book-outline"></i>
                  </div>
                  <div className="feature-single">
                    <h1>{t.BUSINESSINFORMATION[lang]}</h1>
                    <br />
                    <hr id="line" />
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInDown" data-wow-delay="0.5s">
                <img
                  className="img-responsive icons-area-img"
                  src="assets/images/pic1.png"
                  alt="Easy Card"
                />
              </div>
              <div className="col-md-4 features-left">
                <div
                  className="col-md-12 col-sm-6 wow fadeInDown"
                  data-wow-delay="0.6s"
                >
                  <div className="icons">
                    {" "}
                    <i className="ion-ios-cloud-download-outline"></i>
                  </div>
                  <div className="feature-singles">
                    <h1>
                      {t.CONTENTMANAGEMENTABILITY[lang]}
                      <br></br>
                      {t.CONTENTMANAGEMENTABILITY1[lang]}
                    </h1>
                    <hr id="line" />
                  </div>
                </div>
                <div
                  className="col-md-12 col-sm-6 wow fadeInDown recommendations"
                  data-wow-delay="0.7s"
                >
                  <div className="icons">
                    {" "}
                    <i className="ion-ios-paper-outline"></i>
                  </div>
                  <div className="feature-singles">
                    <h1>{t.RECOMMENDATIONS[lang]}</h1>
                    <hr id="line" />
                  </div>
                </div>
                <div
                  className="col-md-12 col-sm-6 wow fadeInDown"
                  data-wow-delay="0.8s"
                >
                  <div className="icons">
                    {" "}
                    <i className="ion-ios-chatboxes-outline"></i>
                  </div>
                  <div className="feature-singles">
                    <h1>{t.LEAVEAMESSAGEFORM[lang]}</h1>
                    <br />
                    <hr id="line" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-sm-6 wow fadeInDown easily-share-your-card"
              data-wow-delay="0.9s"
            >
              <div className="icons">
                <svg className="svg-icon-1" aria-hidden="true">
                  <use xlinkHref="assets/images/svg-icons/sprite.svg#share-arrow-icon"></use>
                </svg>{" "}
                <i className="ios-undo-outline"></i>
              </div>
              <div className="feature-singles">
                <h1>{t.EASILYSHAREYOURCARD[lang]}</h1>
              </div>
            </div>
            <div className="app-features-btn">
              <a
                href={`https://portal.easy-card.co/#/register/${lang}`}
                className="btn btn2"
              >
                {t.CreateyourownEasyCard[lang]}
              </a>
            </div>
          </div>
          <div className="pitch text-center" id="how-it-works">
            <div className="container">
              <div className="pitch-intro">
                <h1
                  className="wow fadeInDown how-it-works-heading"
                  data-wow-delay="0.2s"
                >
                  {t.Howitworks[lang]}
                </h1>
                <p
                  className="wow fadeInDown"
                  data-wow-delay="0.2s"
                  className="how-it-works-p"
                >
                  {t.ONLYFEW[lang]}
                </p>
              </div>
              <div className="col-md-12">
                <div
                  className="col-md-4 col-sm-4 wow fadeInDown icon-1-div"
                  data-wow-delay="0.2s"
                >
                  <img src="assets/images/icon-1.png" />
                  <div className="pitch-content">
                    <div id="create-En">
                      <h1>
                        1. {t.CreateYourOwnAccount[lang]}
                        <br />
                        {/* {t.CreateYourOwnAccount1[lang]} */}
                      </h1>
                      <p>{t.PLEASEREGISTER[lang]}</p>
                      <a
                        href={`https://portal.easy-card.co/#/register/`}
                        className="btn-link works-section-area"
                      >
                        {t.PLEASEREGISTERBTN[lang]}
                      </a>
                    </div>
                    <div id="create-He">
                      <h1>
                        1. צור חשבון
                        <br />
                        {/* {t.CreateYourOwnAccount1[lang]} */}
                      </h1>
                      <p>הירשם כאן כדי להתחיל להשתמש בשירות שלנו</p>
                      <a
                        href={`https://portal.easy-card.co/#/register/He`}
                        className="btn-link works-section-area"
                      >
                        הירשם
                      </a>
                    </div>
                    <div id="create-Ar">
                      <h1>
                        1. أنشئ حسابك الخاص
                        <br />
                        {/* {t.CreateYourOwnAccount1[lang]} */}
                      </h1>
                      <p>المرجو التسجيل من هنا للاستفادة من خدمتنا</p>
                      <a
                        href={`https://portal.easy-card.co/#/register/Ar`}
                        className="btn-link works-section-area"
                      >
                        تسجل
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-4  col-sm-4 wow fadeInDown icon-2-div"
                  data-wow-delay="0.2s"
                >
                  <img src="assets/images/icon-2.png" />
                  <div className="pitch-content">
                    <h1>
                      2. {t.CreateyourownEasyCard[lang]} <br />{" "}
                      {t.CreateyourownEasyCardd[lang]}{" "}
                    </h1>
                    <p>
                      i. {t.CreateyourownEasyCard1[lang]}
                      <br />
                      ii. {t.CreateyourownEasyCard2[lang]}
                      <br />
                      iii. {t.CreateyourownEasyCard3[lang]}
                      <br />
                      iv. {t.CreateyourownEasyCard4[lang]}
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-4  col-sm-4 wow fadeInDown icon-3-div"
                  data-wow-delay="0.2s"
                >
                  <img src="assets/images/icon-3.png" />
                  <div className="pitch-content">
                    <div id="pr-En">
                      <h1>
                        3. {t.PreviewYourNewEasyCard[lang]}
                        <br></br>
                        {t.PreviewYourNewEasyCardd[lang]}
                      </h1>
                      <p>{t.PreviewYourNewEasyCard1[lang]}</p>
                    </div>

                    <div id="pr-He">
                      <h1>
                        3. צפה בכרטיס ה Easy Card החדש שלך
                        <br></br>
                        {""}
                      </h1>
                      <p>
                        צפה בכרטיס ה Easy Card כדי שתוכל לעדכן לשביעות רצונך או
                        לפרסם אותו
                      </p>
                    </div>

                    <div id="pr-Ar">
                      <h1>
                        3.قم بمعاينة بطاقة Easy Card الجديدة الخاصة بك
                        <br></br>
                      </h1>
                      <p>
                        يمكنك معاينة بطاقة Easy Card حتى تقرر ما إذا كنت تريد
                        إجراء المزيد من التعديلات أو نشرها.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="col-md-6  col-sm-6 wow fadeInDown icon-4-div"
                  data-wow-delay="0.2s"
                >
                  <img src="assets/images/icon-4.png" />
                  <div className="pitch-content">
                    <div id="comp-En">
                      <h1>
                        4. {t.CompleteThePaymentProcess[lang]} <br />{" "}
                        {t.CompleteThePaymentProcesss[lang]}
                      </h1>
                      <p>
                        <p>{t.CompleteThePaymentProcess1[lang]}</p>
                      </p>
                    </div>

                    <div id="comp-He">
                      <h1>
                        4. בצע תשלום <br />{" "}
                      </h1>
                      <p>
                        <p>
                          בחר את החבילה המועדפת עליך להפעיל את ה Easy Card שלך
                        </p>
                      </p>
                    </div>

                    <div id="comp-Ar">
                      <h1>
                        4. أكمل عملية الدفع <br />{" "}
                      </h1>
                      <p>
                        <p>حدد خطتك المفضلة لتفعيل بطاقة Easy Card الخاصة بك</p>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-6 wow fadeInDown  icon-5-div"
                  data-wow-delay="0.2s"
                >
                  <img src="assets/images/icon-5.png" />
                  <div className="pitch-content">
                    <div id="share-En">
                      <h1>
                        5. {t.ShareYourEasyCardWithYourClientsPartners[lang]}{" "}
                        <br />{" "}
                        {t.ShareYourEasyCardWithYourClientsPartnerss[lang]}
                      </h1>
                    </div>

                    <div id="share-He">
                      <h1>
                        5. שתף את כרטיס ה Easy Card שלך עם הלקוחות והשותפים שלך
                      </h1>
                    </div>

                    <div id="share-Ar">
                      <h1>
                        5. شارك بطاقة Easy Card الخاصة بك مع العملاء والشركاء
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="feature-sub" id="price">
            <div className="container">
              <div className="sub-inner mx-auto">
                <h1 className="wow fadeInUp">{t.price[lang]}</h1>

                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="yearly-image">
                          <p>
                            {this.getpackageprice("Yearly")}
                            <span>{this.state.currencySymbol}</span>
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="monthly-image">
                          <p>
                            {this.getpackageprice("Monthly")}
                            <span>{this.state.currencySymbol}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <ul>
								{
                            this.state.packages.map(
                                    
                                    singlePackage => 
                                        
									<li className="pkg-prices-web"><span>{singlePackage.language} : {singlePackage.price} {singlePackage.currency} | {singlePackage.type}</span></li>
                                                           
                            )
                        }
								</ul> */}
                <a
                  href={`https://portal.easy-card.co/#/register/${lang}`}
                  className="btn btn3"
                >
                  {t.mainbtn[lang]}
                </a>
              </div>
            </div>
          </div>
          <div id="img-slider-background">
            <div className="container">
              <div className="row">
                <div className="pitch-intro">
                  <center>
                    <h1 className="wow fadeInDown" data-wow-delay="0.2s">
                      {t.ex[lang]}
                    </h1>
                    <p className="wow fadeInDown" data-wow-delay="0.2s">
                      {t.ex1[lang]}
                    </p>
                  </center>
                </div>
                <div className="img-slider-box col-8">
                  <div className="carousel-container">
                    <div className="carousel">
                      <div className="slideControls">
                        <a className="slidePrev">
                          <div className="arrows-wrapper">
                            <svg className="svg-icon-left" aria-hidden="true">
                              <use xlinkHref="assets/images/svg-icons/sprite.svg#left-arrow-icon"></use>
                            </svg>
                          </div>
                        </a>
                        <a className="slideNext">
                          <div className="arrows-wrapper">
                            <svg className="svg-icon-right" aria-hidden="true">
                              <use xlinkHref="assets/images/svg-icons/sprite.svg#right-arrow-icon"></use>
                            </svg>
                          </div>
                        </a>
                      </div>
                      <ul id="light-slider">
                        {/* <li>
                          <div className="one slide">
                            <figure className="figure">
                              <img
                                src="assets/images/mock1.jpg"
                                className="figure-img img-fluid rounded"
                                alt="Easy Card Example"
                              />
                              <p className="figcaption figure-caption text-center">
                                A caption for the above image
                              </p>
                            </figure>
                          </div>
                        </li>
                        <li>
                          <div className="one slide">
                            <figure className="figure">
                              <img
                                src="assets/images/mock2.jpg"
                                className="figure-img img-fluid rounded"
                                alt="Easy Card Example"
                              />
                              <p className="figcaption figure-caption text-center">
                                A caption for the above image
                              </p>
                            </figure>
                          </div>
                        </li> */}
                        <li>
                          <div className="one slide">
                            <figure className="figure">
                              <img
                                src="assets/images/m3.png"
                                className="figure-img img-fluid rounded"
                                alt="Easy Card Example"
                              />
                              {/* <p className="figcaption figure-caption text-center">
                                A caption for the above image
                              </p> */}
                            </figure>
                          </div>
                        </li>
                        <li>
                          <div className="one slide">
                            <figure className="figure">
                              <img
                                src="assets/images/m4.png"
                                className="figure-img img-fluid rounded"
                                alt="Easy Card Example"
                              />
                              {/* <p className="figcaption figure-caption text-center">
                                A caption for the above image
                              </p> */}
                            </figure>
                          </div>
                        </li>
                        <li>
                          <div className="one slide">
                            <figure className="figure">
                              <img
                                src="assets/images/m1.png"
                                className="figure-img img-fluid rounded"
                                alt="Easy Card Example"
                              />
                              {/* <p className="figcaption figure-caption text-center">
                                A caption for the above image
                              </p> */}
                            </figure>
                          </div>
                        </li>
                        <li>
                          <div className="one slide">
                            <figure className="figure">
                              <img
                                src="assets/images/m2.png"
                                className="figure-img img-fluid rounded"
                                alt="Easy Card Example"
                              />
                              {/* <p className="figcaption figure-caption text-center">
                                A caption for the above image
                              </p> */}
                            </figure>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="reviews" className="review-section">
            <div className="container">
              <div className="col-md-10 col-md-offset-1">
                <div className="reviews owl-carousel owl-theme">
                  {this.renderTestimonials()}
                  {/* <div className="review-single">
                    <img
                      className="img-circle"
                      src={product.avatar}
                      alt="Client Testimonoal"
                    />
                    <div
                      className="review-text wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <p>{product.content}</p>
                      <h3>- {product.name}</h3>
                      <h3>{product.position}</h3>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="container-contact100" id="contact-us">
            <div className="wrap-contact100">
              <form className="contact100-form validate-form">
                {" "}
                <span className="contact100-form-title">
                  {t.contactus[lang]}
                </span>
                <div
                  className="wrap-input100 rs1-wrap-input100 validate-input"
                  data-validate="Type first name"
                >
                  <input
                    className="input100"
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={this.state.first_name}
                    onChange={this.onChange}
                    placeholder={t.contactusfname[lang]}
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 rs2-wrap-input100 validate-input"
                  data-validate="Type last name"
                >
                  <input
                    className="input100"
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={this.state.last_name}
                    onChange={this.onChange}
                    placeholder={t.contactuslname[lang]}
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    className="input100"
                    type="text"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    placeholder={t.contactusEmail[lang]}
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="wrap-input100">
                  <input
                    className="input100"
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    value={this.state.phone_number}
                    onChange={this.onChange}
                    placeholder={t.contactusphonenumber[lang]}
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Message is required"
                >
                  <textarea
                    className="input100"
                    name="message"
                    id="message"
                    value={this.state.message}
                    onChange={this.onChange}
                    placeholder={t.Leaveusamessage[lang]}
                    required
                  ></textarea>{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="container-contact100-form-btn">
                  <button
                    className="contact100-form-btn btn4"
                    type="button"
                    onClick={this.sendMessage}
                  >
                    {t.Sendmessage[lang]}
                  </button>
                </div>
              </form>
              <div className="contact100-more flex-col-c-m">
                <div className="size1">
                  <div className="flex-col size2">
                    <p className="txt1 p-b-20">{t.Contactusvia[lang]}</p>
                    <br />
                    <div className="contact-flex-containter">
                      <a
                        className="flex-contact-item"
                        href="mailto:support@easy-card.co"
                      >
                        <svg
                          className="svg-icon-contact"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polygon
                            class="st0"
                            points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                          />
                          <polygon class="st0" points="1.4,2.2 0,3.5 0,1 	" />
                          <polygon
                            class="st0"
                            points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                          />
                          <polygon
                            class="st0"
                            points="3.1,2.2 4.5,1 4.5,3.5 	"
                          />
                        </svg>
                      </a>
                      <a
                        className="flex-contact-item"
                        href="https://www.facebook.com/EasyCard"
                      >
                        <svg
                          className="svg-icon-contact"
                          aria-hidden="true"
                          viewBox="0 0 4.5 4.5"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="st0"
                            d="M3.4,0L2.8,0C2.1,0,1.7,0.4,1.7,1.1v0.5H1.1C1.1,1.6,1,1.7,1,1.7v0.7c0,0.1,0,0.1,0.1,0.1h0.6
		v1.9c0,0.1,0,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1V2.5h0.7c0.1,0,0.1,0,0.1-0.1l0-0.7c0,0,0,0,0-0.1c0,0,0,0-0.1,0H2.7V1.2
		c0-0.2,0-0.3,0.3-0.3l0.4,0c0.1,0,0.1,0,0.1-0.1L3.4,0C3.5,0,3.4,0,3.4,0z"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="container footer-distributed">
            <div className="footer-left">
              <img src="assets/images/footer-logo.png" alt="Easy Card" />
              <p className="footer-links">
                {" "}
                <a className="page-scroll" href="#main">
                  {t.home[lang]}
                </a>
                ·{" "}
                <a className="page-scroll" href="#features">
                  {t.Productfeature[lang]}
                </a>
                ·{" "}
                <a className="page-scroll" href="#price">
                  {t.Price[lang]}
                </a>
                ·{" "}
                <a className="page-scroll" href="#how-it-works">
                  {t.Howitworks[lang]}
                </a>
                ·{" "}
                <a className="page-scroll" href="#img-slider-background">
                  {t.Examples[lang]}
                </a>
                ·{" "}
                <a className="page-scroll" href="#reviews">
                  {t.Reviews[lang]}
                </a>
                ·{" "}
                <a className="page-scroll" href="#contact-us">
                  {t.ContactUs[lang]}
                </a>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/terms/and/conditions"
                  target="_blank"
                >
                  {t.Termsandonditions[lang]}
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/privacy/policy"
                  target="_blank"
                >
                  {t.privacypolicy[lang]}
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to={`/Cancellation/Policy/${this.state.urllan}`}
                  target="_blank"
                >
                  {t.cancellationpolicy[lang]}
                </Link>
              </p>
              <p className="footer-company-name">
                © {new Date().getFullYear()} Easy Card |{" "}
                {t.allrightsreserved[lang]}
              </p>
            </div>
            <div className="footer-center">
              <div>
                {" "}
                <i className="fa fa-envelope"></i>
                <p>
                  <a href="mailto:support@easy-card.co">support@easy-card.co</a>
                </p>
              </div>
              <div>
                {" "}
                <i className="fab fa-facebook-f"></i>
                <p>
                  <a href="https://www.facebook.com/EasyCard">
                    www.facebook.com/EasyCard
                  </a>
                </p>
              </div>
            </div>
            <div className="footer-right">
              <p className="footer-company-about">
                {" "}
                <span>{t.Businessaddress[lang]}</span>{" "}
                <a href="#">{t.Businessaddress1[lang]}</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Phone number</span>
                <a href="#">+972-54-9950199</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>{t.Link[lang]}</span>
                <li>
                  <a href={`https://portal.easy-card.co/#/login/${lang}`}>
                    {t.Signin[lang]}
                  </a>
                </li>
                <li>
                  <a href={`https://portal.easy-card.co/#/register/${lang}`}>
                    {t.SignUp[lang]}
                  </a>
                </li>
              </p>
              <div className="footer-icons">
                <a
                  href={`https://portal.easy-card.co/#/register/${lang}`}
                  className="btn btn5"
                >
                  {t.mainbtn[lang]}
                </a>
              </div>
            </div>
            <br />
          </footer>
        </div>
        <a id="back-top" className="back-to-top page-scroll" href="#main">
          {" "}
          <i className="ion-ios-arrow-thin-up"></i>
        </a>
        <Script
          url="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.js"
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
  };
};
const mapDispatchToProps = () => {
  return {
    getTestimonials: () => userActionCreater.getTestimonials(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
