import React, { Component } from "react";
import { BrowserRouter, HashRouter, Route } from "react-router-dom";
import Home from "./Components/Home";
import TC from "./Components/TermsAndConditions";
import PC from "./Components/PrivacyPolicy";
import Card from "./Components/Card/Card";
import LogedInUserCard from "./Components/Card/LogedInUserCard";
import CP from "./Components/CancellationPolicy";
import CPHB from "./Components/CancelationPolicyHb";
import CPAB from "./Components/CancelationPolicyAb";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route exact path="/" name="Home" component={Home} />
        <Route exact path="/:slug" name="Card" component={Card} />
        <Route
          exact
          path="/card/:userid/view/:slug"
          name="LogedInUserCard"
          component={LogedInUserCard}
        />
        <Route
          exact
          path="/terms/and/conditions"
          name="Term And Conditions"
          component={TC}
        />
        <Route
          exact
          path="/Cancellation/Policy"
          name="cancelation Policy"
          component={CP}
        />
        <Route
          exact
          path="/Cancellation/Policy/Hb"
          name="cancelation Policy"
          component={CPHB}
        />
        <Route
          exact
          path="/Cancellation/Policy/Ab"
          name="cancelation Policy"
          component={CPAB}
        />
        <Route
          exact
          path="/privacy/policy"
          name="Privacy Policy"
          component={PC}
        />
      </BrowserRouter>
    );
  }
}

export default App;
