import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import T from "../Helpers/Translations";
import * as actions from "../Store/Actions/type";
import * as userActionCreater from "../Store/Actions/UserActions";

class CancellationPolicy extends Component {
  state = {
    user: {},
    prices: {}
  };

  getPrices = () => {
    let { getPrices, errorHandler } = this.props;
    getPrices()
      .then(res => {
        this.setState({
          prices: res.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  getTestimonials = () => {
    let { getTestimonials, errorHandler } = this.props;
    getTestimonials()
      .then(res => {
        this.setState({
          testimonials: res.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  componentDidMount() {
    this.getPrices();
    this.getTestimonials();
  }

  componentWillMount() {
    $("html").attr("dir", "ltr");
    this.render();
  }

  render() {
    let { prices, testimonials } = this.state;
    let lang = $("html")
      .attr("lang")
      .toUpperCase();
    let t = T[0];
    return (
      <div className="wrapper">
        <div className="container">
          <nav className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="navbar-header page-scroll">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a
                  className="navbar-brand page-scroll"
                  href="https://dev.easy-card.co/#/"
                >
                  <img
                    src="/assets/images/easy-card.png"
                    width="105"
                    height="35"
                    alt="iLand"
                  />
                </a>
              </div>
              <div
                className="collapse navbar-collapse navbar-right"
                id="bs-example-navbar-collapse-1"
                style={{ display: "none" }}
              >
                <div className="tm-link-cont">
                  <div className="tm-arrow-wrapper">
                    <svg className="svg-icon-left" aria-hidden="true">
                      <use xlinkHref="/assets/images/svg-icons/sprite.svg#left-arrow-icon"></use>
                    </svg>
                  </div>
                  <Link to="/" className="tm-link">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="main form" id="main">
          <div className="container">
            <div className="col-md-12">
              <div className="tm-main"></div>
              <div className="hcp">
                <h2>Policy of Cancellation of Services and Refunds </h2>
                <h1>General</h1>
                <div className="cpp">
                  {/* <ol>
                    <li>
                      Services are provided by A.B. Business and Social
                      Entrepreneurship(hereinafter <span>"the Company"</span>).
                    </li>
                    <li>
                      The Company's services are sold to businessmen, managers,
                      registered businesses, tax-exempt companies, company
                      owners, etc., as well as private individuals for personal
                      use,for promoting their business goals and enterprises
                      (hereinafter <span>"Customer"</span>).
                    </li>
                    <li>
                      Cancellation of services and receipt ofa monetary refundis
                      possible according to the law and according to the
                      Company's policy, which shall be detailed below.
                    </li>
                    <li>
                      This policy document is the sole policy that obligates
                      both the Company and its Customers, however, it does not
                      diminish the right of its Customers to cancel a
                      transactionin accordance to the law, when the provisions
                      of the law are better and superior than the provisions
                      described in this policy.
                    </li>
                    <li>
                      Detailed below is a summary of alloptions of cancellation,
                      refunds and credits. The Company, at its sole discretion,
                      retains the right to allow the Customer to exchange one
                      service for another, where the Company deems it justified
                      under the circumstances.
                    </li>
                    <li>
                      The following types of services are provided by the
                      Company:
                      <ol>
                        <li>
                          Monthly plan: A monthly subscription is unlimited in
                          time, whereby the first month after joining the
                          service is free, in accordance with the packages
                          detailed on the website, which are subject to changeat
                          the Company's discretion.
                        </li>
                        <li>
                          Annual plan: an annual subscription at a discounted
                          price; payment is to be made upon purchasing the
                          subscription.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The following provisions will apply to aCustomer who
                      exercises itsright to cancel and receive a refund as
                      described in this policy and/or according to the law:
                      <ol>
                        <li>
                          The Customermay selectone of the following two plans:
                          An annual plan or a monthly plan, as detailed in
                          Section 6.
                        </li>
                        <li>
                          Upon execution of the contract between the parties,
                          the Customerwill enjoy free usage of the service
                          provided by the Company, for one month
                        </li>
                        <li>
                          ACustomeron an annual plan,who desires to cancel the
                          purchase of the service,will be charged
                          proportionately for that year, provided that the
                          Customer notifies the Company up to 14 days prior to
                          the cessation of theservice.
                        </li>
                        <li>
                          A Customer on a monthly plan,who desires to cancel the
                          purchase of the service, will be charged full payment
                          for that month, provided that the Customer notifies
                          the Company up to 14 days prior to the cessation of
                          the service.
                        </li>
                        <li>
                          A Customer on an annual plancannot cancel a service
                          provided bythe Company more than once in two years.
                        </li>
                        <li>
                          A Customer on a monthly plan cannot cancel a service
                          provided by the Company more than once a year, all at
                          the discretion of the Company.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If one of the Company's services was purchased with a
                      discount voucher, and the Customercancelled the
                      aforementioned service, the Company is not obligated to
                      provide that sameservice at the same cost. The Customer
                      will have to purchase the Company's services in accordance
                      with the plans listed above.
                    </li>
                    <li>
                      Theuse of the masculine gender in this policy is for
                      convenience only, and it applies both to males and females
                      equally. The same is true regarding the usage of singular
                      versus plural; each instance as appropriate.
                    </li>
                    <li>
                      The above summarizes all options of cancellation of the
                      transactions, termination of service, refunds and credits,
                      however, the Company reserves the right, at its sole
                      discretion, to allow the Customer to exchange one service
                      for another, if the Company deems it fitting under the
                      circumstances.
                    </li>
                    <li>
                      We wish you to use and derive significant benefit from
                      each of our products and services.
                    </li>
                  </ol> */}

                  <ol>
                  
                  <li>
                      As a rule, subscriptions to the Company's services are renewed
                      monthly or annually, depending on the type of subscription the
                      customer has chosen to purchase
                    </li>
                    <li>
                      The Customer has the right to use the site, the software and the
                      Company's services for the duration of time he pays for these services.
                    </li>
                    <li>
                      The Customer may cancel his subscription to the Company's services
                      at any time, pursuant to the provisions set forth below.

                    </li>
                    <li>
                      To the extent that any of the provisions below contradict the Consumer
                      Protection Regulations (Cancellation of a Transaction) 5771-2010, the
                      provisions of the Consumer Protection Regulations (Cancellation of a
                      Transaction) 5771-2010 shall prevail.
                    </li>
                    <li>
                      In any event of cancellation, the Customer shall be obligated to pay for
                      the services provided until the effective date of the cancellation.
                    </li>
                    <li>
                      The customer must notify the Company in writing (via an email to the
                      Company's customer service: support@easy-card.co), of his intent to
                      cease receiving the service, at least 7 days before the subscription
                      renewal date.
                    </li>
                    <li>
                      Upon receipt of the Customer's notice at the Company's offices, the
                      Company will cease providing the service to the customer at the next
                      renewal date:
                      <ol>
                        <li>
                          In a subscription that renews monthly, the service can only be
                          ceased once the month is over.
                        </li>
                        <li>
                          In a subscription that renews annually, the service can only be
                          ceased once the year is over.
                        </li>
                        <li>
                          In addition, in a subscription that renews annually, the Customer
                          has the option, at its discretion, to cease the service at the end of
                          the month in which he provided notice of his intent to cease, upon
                          payment of the difference between the then current monthly
                          subscription cost and the annual cost paid divided by 12, and
                          multiplied by the amount of months he was subscribed to the
                          service, up till the end of the month in which he provided notice of
                          his intent to cease the service
                        </li>
                        <li>
                          For the avoidance of doubt, in the event of cancellation, the
                          cancellation will take effect on the date of the next upcoming debit
                          date.
                        </li>
                      </ol>
                    </li>

                    <li>
                      Refunds will be provided using the same card through which charges
                      were made and the Customer is obligated to provide the Company with
                      all the details required for the purpose of providing the refund.
                    </li>

                    <li>The Company may refuse and reject any request for subscriptions /
                      renewals without the need for any reasoning or explanation.
                    </li>

                    <li>
                      The Company may cease providing the service without notice to a
                      subscriber who distributes inappropriate and / or offensive content
                      and / or messages.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="container-contact100" id="contact-us">
            <div className="wrap-contact100">
              <form className="contact100-form validate-form">
                {" "}
                <span className="contact100-form-title">Contact Us</span>
                <div
                  className="wrap-input100 rs1-wrap-input100 validate-input"
                  data-validate="Type first name"
                >
                  <input
                    id="first-name"
                    className="input100"
                    type="text"
                    name="first-name"
                    placeholder="First name"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 rs2-wrap-input100 validate-input"
                  data-validate="Type last name"
                >
                  <input
                    className="input100"
                    type="text"
                    name="last-name"
                    placeholder="Last name"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    id="email"
                    className="input100"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="wrap-input100">
                  <input
                    id="phone"
                    className="input100"
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Message is required"
                >
                  <textarea
                    id="message"
                    className="input100"
                    name="message"
                    placeholder="Leave us a message"
                    required
                  ></textarea>{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="container-contact100-form-btn">
                  <button className="contact100-form-btn btn4">
                    SEND MESSAGE
                  </button>
                </div>
              </form>
              <div className="contact100-more flex-col-c-m">
                <div className="size1">
                  <div className="flex-col size2">
                    <p className="txt1 p-b-20">Contact us via</p>
                    <br />
                    <div className="contact-flex-containter">
                      <a
                        className="flex-contact-item"
                        href="mailto:support@easy-card.co"
                      >
                        <svg className="svg-icon-contact" aria-hidden="true">
                          <use xlinkHref="/assets/images/svg-icons/sprite.svg#envelope-icon"></use>
                        </svg>
                      </a>
                      <a
                        className="flex-contact-item"
                        href="https://www.facebook.com/EasyCard"
                      >
                        <svg className="svg-icon-contact" aria-hidden="true">
                          <use xlinkHref="/assets/images/svg-icons/sprite.svg#facebook-icon"></use>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="container footer-distributed">
            <div className="footer-left">
              <img src="/assets/images/footer-logo.png" alt="Easy Card" />
              <p className="footer-links">
                {" "}
                <a className="page-scroll" href="#main">
                  Home
                </a>
                ·{" "}
                <a className="page-scroll" href="#features">
                  Product Features
                </a>
                ·{" "}
                <a className="page-scroll" href="#price">
                  Price
                </a>
                ·{" "}
                <a className="page-scroll" href="#how-it-works">
                  How It Works
                </a>
                ·{" "}
                <a className="page-scroll" href="#img-slider-background">
                  Examples
                </a>
                ·{" "}
                <a className="page-scroll" href="#reviews">
                  Reviews
                </a>
                ·{" "}
                <a className="page-scroll" href="#contact-us">
                  Contact Us
                </a>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/terms/and/conditions"
                  target="_blank"
                >
                  Terms And Conditions
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/privacy/policy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/Cancellation/Policy"
                  target="_blank"
                >
                  Cancellation Policy
                </Link>
              </p>
              <p className="footer-company-name">
                © 2020 Easy Card | All rights reserved.
              </p>
            </div>
            <div className="footer-center">
              <div>
                {" "}
                <i className="fa fa-envelope"></i>
                <p>
                  <a href="mailto:support@easy-card.co">support@easy-card.co</a>
                </p>
              </div>
              <div>
                {" "}
                <i className="fab fa-facebook-f"></i>
                <p>
                  <a href="https://www.facebook.com/EasyCard">
                    www.facebook.com/EasyCard
                  </a>
                </p>
              </div>
            </div>
            <div className="footer-right">
              <p className="footer-company-about">
                {" "}
                <span>Business address</span>{" "}
                <a href="#">Yafa Yarkoni 14, Rosh Ha'ayin, Israel</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Phone number</span>
                <a href="#">+972-54-9950199</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Link</span>·{" "}
                <a href="https://portal.easy-card.co/">Sign In</a> &nbsp;&nbsp;
                · <a href="https://portal.easy-card.co/#/register">Sign Up</a>
              </p>
              <div className="footer-icons">
                <a
                  href="https://portal.easy-card.co/#/register"
                  className="btn btn5"
                >
                  Create your own Easy Card
                </a>
              </div>
            </div>
            <br />
          </footer>
        </div>
        <a id="back-top" className="back-to-top page-scroll" href="#main">
          {" "}
          <i className="ion-ios-arrow-thin-up"></i>
        </a>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.AuthReducer.user
  };
};
const mapDispatchToProps = () => {
  return {
    getPrices: () => userActionCreater.getPrices(),
    getTestimonials: () => userActionCreater.getTestimonials()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancellationPolicy);
