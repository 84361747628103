import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import T from "../Helpers/Translations";
import * as actions from "../Store/Actions/type";
import * as userActionCreater from "../Store/Actions/UserActions";

class TermsAndConditions extends Component {
	state = {
		user: {},
		prices: {}
	};

	getPrices = () => {
		let { getPrices, errorHandler } = this.props;
		getPrices()
			.then((res) => {
				this.setState({
					prices: res.data
				});
			})
			.catch(errorHandler)
			.finally(() => {
				this.setState({
					isLoading: false
				});
			});
	};

	getTestimonials = () => {
		let { getTestimonials, errorHandler } = this.props;
		getTestimonials()
			.then((res) => {
				this.setState({
					testimonials: res.data
				});
			})
			.catch(errorHandler)
			.finally(() => {
				this.setState({
					isLoading: false
				});
			});
	};

	componentDidMount() {
		this.getPrices();
		this.getTestimonials();
	}

	componentWillMount() {
		if (
			this.props.user !== null &&
			this.props.user !== "null" &&
			this.props.user !== undefined
		) {
			this.setState({
				user: this.props.user
			});
			let lang = this.props.user.language;
			if (lang === "HE" || lang === "AR") {
				$("html").attr("dir", "rtl");
				$("html").attr("lang", lang);
				this.render();
			} else {
				$("html").attr("dir", "ltr");
				$("html").attr("lang", lang);
				this.render();
			}
		}
	}

	render() {
		let { prices, testimonials } = this.state;
		let lang = $("html")
			.attr("lang")
			.toUpperCase();
		let t = T[0];
		return (
			<div className="wrapper">
				<div className="container">
					<nav className="navbar navbar-default navbar-fixed-top">
						<div className="container">
							<div className="navbar-header page-scroll">
								<button
									type="button"
									className="navbar-toggle"
									data-toggle="collapse"
									data-target="#bs-example-navbar-collapse-1"
									aria-expanded="false"
								>
									<span className="sr-only">
										Toggle navigation
									</span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
								<a
									className="navbar-brand page-scroll"
									href="https://dev.easy-card.co/#/"
								>
									<img
										src="/assets/images/easy-card.png"
										width="105"
										height="35"
										alt="iLand"
									/>
								</a>
							</div>
							<div
								className="collapse navbar-collapse navbar-right"
								id="bs-example-navbar-collapse-1"
								style={{ display: "none" }}
							>
								<div className="tm-link-cont">
									<div className="tm-arrow-wrapper">
										<svg
											className="svg-icon-left"
											aria-hidden="true"
										>
											<use xlinkHref="/assets/images/svg-icons/sprite.svg#left-arrow-icon"></use>
										</svg>
									</div>
									<Link
										onClick={() =>
											this.props.history.push("/")
										}
										className="tm-link"
									>
										Back
									</Link>
								</div>
							</div>
						</div>
					</nav>
				</div>
				<div className="main form" id="main">
					<div className="container">
						<div className="col-md-12">
							<div className="tm-main">
								<h1>Terms And Conditions</h1>
								<br />
								<p>
									These terms and conditions ("Terms",
									"Agreement") are an agreement between
									Website Operator ("Website Operator", "us",
									"we" or "our") and you ("User", "you" or
									"your"). This Agreement sets forth the
									general terms and conditions of your use of
									the easy-card.co website and any of its
									products or services (collectively,
									"Website" or "Services").
								</p>

								<div className="tm-card">
									<h2>Accounts and membership</h2>
									<p>
										You must be at least 13 years of age to
										use this Website. By using this Website
										and by agreeing to this Agreement you
										warrant and represent that you are at
										least 13 years of age. If you create an
										account on the Website, you are
										responsible for maintaining the security
										of your account and you are fully
										responsible for all activities that
										occur under the account and any other
										actions taken in connection with it. We
										may, but have no obligation to, monitor
										and review new accounts before you may
										sign in and use our Services. Providing
										false contact information of any kind
										may result in the termination of your
										account. You must immediately notify us
										of any unauthorized uses of your account
										or any other breaches of security. We
										will not be liable for any acts or
										omissions by you, including any damages
										of any kind incurred as a result of such
										acts or omissions. We may suspend,
										disable, or delete your account (or any
										part thereof) if we determine that you
										have violated any provision of this
										Agreement or that your conduct or
										content would tend to damage our
										reputation and goodwill. If we delete
										your account for the foregoing reasons,
										you may not re-register for our
										Services. We may block your email
										address and Internet protocol address to
										prevent further registration.
									</p>
								</div>

								<div className="tm-card">
									<h2>User content</h2>
									<p>
										We do not own any data, information or
										material ("Content") that you submit on
										the Website in the course of using the
										Service. You shall have sole
										responsibility for the accuracy,
										quality, integrity, legality,
										reliability, appropriateness, and
										intellectual property ownership or right
										to use of all submitted Content. We may,
										but have no obligation to, monitor and
										review Content on the Website submitted
										or created using our Services by you.
										Unless specifically permitted by you,
										your use of the Website does not grant
										us the license to use, reproduce, adapt,
										modify, publish or distribute the
										Content created by you or stored in your
										user account for commercial, marketing
										or any similar purpose. But you grant us
										permission to access, copy, distribute,
										store, transmit, reformat, display and
										perform the Content of your user account
										solely as required for the purpose of
										providing the Services to you. Without
										limiting any of those representations or
										warranties, we have the right, though
										not the obligation, to, in our own sole
										discretion, refuse or remove any Content
										that, in our reasonable opinion,
										violates any of our policies or is in
										any way harmful or objectionable.
									</p>
								</div>
								<div className="tm-card">
									<h2>Billing and payments</h2>
									<p>
										You shall pay all fees or charges to
										your account in accordance with the
										fees, charges, and billing terms in
										effect at the time a fee or charge is
										due and payable. Where Services are
										offered on a free trial basis, payment
										may be required after the free trial
										period ends, and not when you enter your
										billing details (which may be required
										prior to the commencement of the free
										trial period). If auto-renewal is
										enabled for the Services you have
										subscribed for, you will be charged
										automatically in accordance with the
										term you selected. If, in our judgment,
										your purchase constitutes a high-risk
										transaction, we will require you to
										provide us with a copy of your valid
										government-issued photo identification,
										and possibly a copy of a recent bank
										statement for the credit or debit card
										used for the purchase. We reserve the
										right to change products and product
										pricing at any time. We also reserve the
										right to refuse any order you place with
										us. We may, in our sole discretion,
										limit or cancel quantities purchased per
										person, per household or per order.
										These restrictions may include orders
										placed by or under the same customer
										account, the same credit card, and/or
										orders that use the same billing and/or
										shipping address. In the event that we
										make a change to or cancel an order, we
										may attempt to notify you by contacting
										the e-mail and/or billing address/phone
										number provided at the time the order
										was made.
									</p>
								</div>

								<div className="tm-card">
									<h2>Accuracy of information</h2>
									<p>
										Occasionally there may be information on
										the Website that contains typographical
										errors, inaccuracies or omissions that
										may relate to product descriptions,
										pricing, availability, promotions and
										offers. We reserve the right to correct
										any errors, inaccuracies or omissions,
										and to change or update information or
										cancel orders if any information on the
										Website or on any related Service is
										inaccurate at any time without prior
										notice (including after you have
										submitted your order). We undertake no
										obligation to update, amend or clarify
										information on the Website including,
										without limitation, pricing information,
										except as required by law. No specified
										update or refresh date applied on the
										Website should be taken to indicate that
										all information on the Website or on any
										related Service has been modified or
										updated.
									</p>
								</div>

								<div className="tm-card">
									<h2>Backups</h2>
									<p>
										We are not responsible for Content
										residing on the Website. In no event
										shall we be held liable for any loss of
										any Content. It is your sole
										responsibility to maintain appropriate
										backup of your Content. Notwithstanding
										the foregoing, on some occasions and in
										certain circumstances, with absolutely
										no obligation, we may be able to restore
										some or all of your data that has been
										deleted as of a certain date and time
										when we may have backed up data for our
										own purposes. We make no guarantee that
										the data you need will be available.
									</p>
								</div>

								<div className="tm-card">
									<h2>Advertisements</h2>
									<p>
										During use of the Website, you may enter
										into correspondence with or participate
										in promotions of advertisers or sponsors
										showing their goods or services through
										the Website. Any such activity, and any
										terms, conditions, warranties or
										representations associated with such
										activity, is solely between you and the
										applicable third-party. We shall have no
										liability, obligation or responsibility
										for any such correspondence, purchase or
										promotion between you and any such
										third-party.
									</p>
								</div>

								<div className="tm-card">
									<h2>Links to other websites</h2>
									<p>
										Although this Website may link to other
										websites, we are not, directly or
										indirectly, implying any approval,
										association, sponsorship, endorsement,
										or affiliation with any linked website,
										unless specifically stated herein. We
										are not responsible for examining or
										evaluating, and we do not warrant the
										offerings of, any businesses or
										individuals or the content of their
										websites. We do not assume any
										responsibility or liability for the
										actions, products, services, and content
										of any other third-parties. You should
										carefully review the legal statements
										and other conditions of use of any
										website which you access through a link
										from this Website. Your linking to any
										other off-site websites is at your own
										risk.
									</p>
								</div>

								<div className="tm-card">
									<h2>Prohibited uses</h2>
									<p>
										In addition to other terms as set forth
										in the Agreement, you are prohibited
										from using the Website or its Content:
										(a) for any unlawful purpose; (b) to
										solicit others to perform or participate
										in any unlawful acts; (c) to violate any
										international, federal, provincial or
										state regulations, rules, laws, or local
										ordinances; (d) to infringe upon or
										violate our intellectual property rights
										or the intellectual property rights of
										others; (e) to harass, abuse, insult,
										harm, defame, slander, disparage,
										intimidate, or discriminate based on
										gender, sexual orientation, religion,
										ethnicity, race, age, national origin,
										or disability; (f) to submit false or
										misleading information; (g) to upload or
										transmit viruses or any other type of
										malicious code that will or may be used
										in any way that will affect the
										functionality or operation of the
										Service or of any related website, other
										websites, or the Internet; (h) to
										collect or track the personal
										information of others; (i) to spam,
										phish, pharm, pretext, spider, crawl, or
										scrape; (j) for any obscene or immoral
										purpose; or (k) to interfere with or
										circumvent the security features of the
										Service or any related website, other
										websites, or the Internet. We reserve
										the right to terminate your use of the
										Service or any related website for
										violating any of the prohibited uses.
									</p>
								</div>

								<div className="tm-card">
									<h2>Intellectual property rights</h2>
									<p>
										This Agreement does not transfer to you
										any intellectual property owned by
										Website Operator or third-parties, and
										all rights, titles, and interests in and
										to such property will remain (as between
										the parties) solely with Website
										Operator. All trademarks, service marks,
										graphics and logos used in connection
										with our Website or Services, are
										trademarks or registered trademarks of
										Website Operator or Website Operator
										licensors. Other trademarks, service
										marks, graphics and logos used in
										connection with our Website or Services
										may be the trademarks of other
										third-parties. Your use of our Website
										and Services grants you no right or
										license to reproduce or otherwise use
										any Website Operator or third-party
										trademarks.
									</p>
								</div>

								<div className="tm-card">
									<h2>Disclaimer of warranty</h2>
									<p>
										You agree that your use of our Website
										or Services is solely at your own risk.
										You agree that such Service is provided
										on an "as is" and "as available" basis.
										We expressly disclaim all warranties of
										any kind, whether express or implied,
										including but not limited to the implied
										warranties of merchantability, fitness
										for a particular purpose and
										non-infringement. We make no warranty
										that the Services will meet your
										requirements, or that the Service will
										be uninterrupted, timely, secure, or
										error-free; nor do we make any warranty
										as to the results that may be obtained
										from the use of the Service or as to the
										accuracy or reliability of any
										information obtained through the Service
										or that defects in the Service will be
										corrected. You understand and agree that
										any material and/or data downloaded or
										otherwise obtained through the use of
										Service is done at your own discretion
										and risk and that you will be solely
										responsible for any damage to your
										computer system or loss of data that
										results from the download of such
										material and/or data. We make no
										warranty regarding any goods or services
										purchased or obtained through the
										Service or any transactions entered into
										through the Service. No advice or
										information, whether oral or written,
										obtained by you from us or through the
										Service shall create any warranty not
										expressly made herein.
									</p>
								</div>

								<div className="tm-card">
									<h2>Limitation of liability</h2>
									<p>
										To the fullest extent permitted by
										applicable law, in no event will Website
										Operator, its affiliates, officers,
										directors, employees, agents, suppliers
										or licensors be liable to any person for
										(a): any indirect, incidental, special,
										punitive, cover or consequential damages
										(including, without limitation, damages
										for lost profits, revenue, sales,
										goodwill, use of content, impact on
										business, business interruption, loss of
										anticipated savings, loss of business
										opportunity) however caused, under any
										theory of liability, including, without
										limitation, contract, tort, warranty,
										breach of statutory duty, negligence or
										otherwise, even if Website Operator has
										been advised as to the possibility of
										such damages or could have foreseen such
										damages. To the maximum extent permitted
										by applicable law, the aggregate
										liability of Website Operator and its
										affiliates, officers, employees, agents,
										suppliers and licensors, relating to the
										services will be limited to an amount
										greater of one dollar or any amounts
										actually paid in cash by you to Website
										Operator for the prior one month period
										prior to the first event or occurrence
										giving rise to such liability. The
										limitations and exclusions also apply if
										this remedy does not fully compensate
										you for any losses or fails of its
										essential purpose.
									</p>
								</div>

								<div className="tm-card">
									<h2>Indemnification</h2>
									<p>
										You agree to indemnify and hold Website
										Operator and its affiliates, directors,
										officers, employees, and agents harmless
										from and against any liabilities,
										losses, damages or costs, including
										reasonable attorneys' fees, incurred in
										connection with or arising from any
										third-party allegations, claims,
										actions, disputes, or demands asserted
										against any of them as a result of or
										relating to your Content, your use of
										the Website or Services or any willful
										misconduct on your part.
									</p>
								</div>

								<div className="tm-card">
									<h2>Severability</h2>
									<p>
										All rights and restrictions contained in
										this Agreement may be exercised and
										shall be applicable and binding only to
										the extent that they do not violate any
										applicable laws and are intended to be
										limited to the extent necessary so that
										they will not render this Agreement
										illegal, invalid or unenforceable. If
										any provision or portion of any
										provision of this Agreement shall be
										held to be illegal, invalid or
										unenforceable by a court of competent
										jurisdiction, it is the intention of the
										parties that the remaining provisions or
										portions thereof shall constitute their
										agreement with respect to the subject
										matter hereof, and all such remaining
										provisions or portions thereof shall
										remain in full force and effect.
									</p>
								</div>

								<div className="tm-card">
									<h2>Dispute resolution</h2>
									<p>
										The formation, interpretation, and
										performance of this Agreement and any
										disputes arising out of it shall be
										governed by the substantive and
										procedural laws of Hamerkaz, Israel
										without regard to its rules on conflicts
										or choice of law and, to the extent
										applicable, the laws of Israel. The
										exclusive jurisdiction and venue for
										actions related to the subject matter
										hereof shall be the state and federal
										courts located in Hamerkaz, Israel, and
										you hereby submit to the personal
										jurisdiction of such courts. You hereby
										waive any right to a jury trial in any
										proceeding arising out of or related to
										this Agreement. The United Nations
										Convention on Contracts for the
										International Sale of Goods does not
										apply to this Agreement.
									</p>
								</div>

								<div className="tm-card">
									<h2>Assignment</h2>
									<p>
										You may not assign, resell, sub-license
										or otherwise transfer or delegate any of
										your rights or obligations hereunder, in
										whole or in part, without our prior
										written consent, which consent shall be
										at our own sole discretion and without
										obligation; any such assignment or
										transfer shall be null and void. We are
										free to assign any of its rights or
										obligations hereunder, in whole or in
										part, to any third-party as part of the
										sale of all or substantially all of its
										assets or stock or as part of a merger.
									</p>
								</div>

								<div className="tm-card">
									<h2>Changes and amendments</h2>
									<p>
										We reserve the right to modify this
										Agreement or its policies relating to
										the Website or Services at any time,
										effective upon posting of an updated
										version of this Agreement on the
										Website. When we do, we will revise the
										updated date at the bottom of this page.
										Continued use of the Website after any
										such changes shall constitute your
										consent to such changes.
									</p>
								</div>

								<div className="tm-card">
									<h2>Acceptance of these terms</h2>
									<p>
										You acknowledge that you have read this
										Agreement and agree to all its terms and
										conditions. By using the Website or its
										Services you agree to be bound by this
										Agreement. If you do not agree to abide
										by the terms of this Agreement, you are
										not authorized to use or access the
										Website and its Services.
									</p>
								</div>

								<div className="tm-card">
									<h2>Contacting us</h2>
									<p>
										If you would like to contact us to
										understand more about this Agreement or
										wish to contact us concerning any matter
										relating to it, you may do so via the
										contact form or send an email to
										<i className="tm-italic">
											<a href="mailto:support@easy-card.co">
												{" "}
												support@easy-card.co
											</a>{" "}
										</i>
										<br />
										<span>
											This document was last updated on{" "}
											<b className="tm-bold">
												October 29, 2019
											</b>
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="container-contact100" id="contact-us">
						<div className="wrap-contact100">
							<form className="contact100-form validate-form">
								{" "}
								<span className="contact100-form-title">
									Contact Us
								</span>
								<div
									className="wrap-input100 rs1-wrap-input100 validate-input"
									data-validate="Type first name"
								>
									<input
										id="first-name"
										className="input100"
										type="text"
										name="first-name"
										placeholder="First name"
										required
									/>{" "}
									<span className="focus-input100"></span>
								</div>
								<div
									className="wrap-input100 rs2-wrap-input100 validate-input"
									data-validate="Type last name"
								>
									<input
										className="input100"
										type="text"
										name="last-name"
										placeholder="Last name"
										required
									/>{" "}
									<span className="focus-input100"></span>
								</div>
								<div
									className="wrap-input100 validate-input"
									data-validate="Valid email is required: ex@abc.xyz"
								>
									<input
										id="email"
										className="input100"
										type="text"
										name="email"
										placeholder="Enter email"
										required
									/>{" "}
									<span className="focus-input100"></span>
								</div>
								<div className="wrap-input100">
									<input
										id="phone"
										className="input100"
										type="text"
										name="phone"
										placeholder="Phone Number"
										required
									/>{" "}
									<span className="focus-input100"></span>
								</div>
								<div
									className="wrap-input100 validate-input"
									data-validate="Message is required"
								>
									<textarea
										id="message"
										className="input100"
										name="message"
										placeholder="Leave us a message"
										required
									></textarea>{" "}
									<span className="focus-input100"></span>
								</div>
								<div className="container-contact100-form-btn">
									<button className="contact100-form-btn btn4">
										SEND MESSAGE
									</button>
								</div>
							</form>
							<div className="contact100-more flex-col-c-m">
								<div className="size1">
									<div className="flex-col size2">
										<p className="txt1 p-b-20">
											Contact us via
										</p>
										<br />
										<div className="contact-flex-containter">
											<a
												className="flex-contact-item"
												href="mailto:support@easy-card.co"
											>
												<svg
													className="svg-icon-contact"
													aria-hidden="true"
												>
													<use xlinkHref="/assets/images/svg-icons/sprite.svg#envelope-icon"></use>
												</svg>
											</a>
											<a
												className="flex-contact-item"
												href="https://www.facebook.com/EasyCard"
											>
												<svg
													className="svg-icon-contact"
													aria-hidden="true"
												>
													<use xlinkHref="/assets/images/svg-icons/sprite.svg#facebook-icon"></use>
												</svg>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<footer className="container footer-distributed">
						<div className="footer-left">
							<img
								src="/assets/images/footer-logo.png"
								alt="Easy Card"
							/>
							<p className="footer-links">
								{" "}
								<a className="page-scroll" href="#main">
									Home
								</a>
								·{" "}
								<a className="page-scroll" href="#features">
									Product Features
								</a>
								·{" "}
								<a className="page-scroll" href="#price">
									Price
								</a>
								·{" "}
								<a className="page-scroll" href="#how-it-works">
									How It Works
								</a>
								·{" "}
								<a
									className="page-scroll"
									href="#img-slider-background"
								>
									Examples
								</a>
								·{" "}
								<a className="page-scroll" href="#reviews">
									Reviews
								</a>
								·{" "}
								<a className="page-scroll" href="#contact-us">
									Contact Us
								</a>
								·{" "}
								<Link
									className="page-scroll"
									to="/terms/and/conditions"
									target="_blank"
								>
									Terms And Conditions
								</Link>
								·{" "}
								<Link
									className="page-scroll"
									to="/privacy/policy"
									target="_blank"
								>
									Privacy Policy
								</Link>
								·{" "}
								<Link
									className="page-scroll"
									to="/Cancellation/Policy"
									target="_blank"
								>
									Cancellation Policy
								</Link>
							</p>
							<p className="footer-company-name">
								© 2020 Easy Card | All rights reserved.
							</p>
						</div>
						<div className="footer-center">
							<div>
								{" "}
								<i className="fa fa-envelope"></i>
								<p>
									<a href="mailto:support@easy-card.co">
										support@easy-card.co
									</a>
								</p>
							</div>
							<div>
								{" "}
								<i className="fab fa-facebook-f"></i>
								<p>
									<a href="https://www.facebook.com/EasyCard">
										www.facebook.com/EasyCard
									</a>
								</p>
							</div>
						</div>
						<div className="footer-right">
							<p className="footer-company-about">
								{" "}
								<span>Business address</span>{" "}
								<a href="#">
									Yafa Yarkoni 14, Rosh Ha'ayin, Israel
								</a>{" "}
							</p>
							<br />
							<p className="footer-company-about">
								{" "}
								<span>Phone number</span>
								<a href="#">+972-54-9950199</a>{" "}
							</p>
							<br />
							<p className="footer-company-about">
								{" "}
								<span>Link</span>·{" "}
								<a href="https://portal.easy-card.co/">
									Sign In
								</a>{" "}
								&nbsp;&nbsp; ·{" "}
								<a href="https://portal.easy-card.co/#/register">
									Sign Up
								</a>
							</p>
							<div className="footer-icons">
								<a
									href="https://portal.easy-card.co/#/register"
									className="btn btn5"
								>
									Create your own Easy Card
								</a>
							</div>
						</div>
						<br />
					</footer>
				</div>
				<a
					id="back-top"
					className="back-to-top page-scroll"
					href="#main"
				>
					{" "}
					<i className="ion-ios-arrow-thin-up"></i>
				</a>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.AuthReducer.user
	};
};
const mapDispatchToProps = () => {
	return {
		getPrices: () => userActionCreater.getPrices(),
		getTestimonials: () => userActionCreater.getTestimonials()
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
