import AuthReducer from "./AuthReducer";
import UserReducer from "./UserReducer";
import CardReducer from "./CardReducer";
import DashboardReducer from "./DashboardReducer";



export default {
    AuthReducer,
    UserReducer,
    CardReducer,
    DashboardReducer,
};