class APIModel {
  //for server
  static URL = "https://backend.easy-card.co/";
  static HOST = "https://backend.easy-card.co/api/";

  // for local
  // static URL = "http://localhost:8000/api/"
  // static HOST = "http://localhost:8000/api/";
}

export default APIModel;
